import type { FC } from 'react';
import React, { useContext } from 'react';
import { useIntl } from 'react-intl-next';

import type { SearchCSS } from '@atlaskit/atlassian-navigation';
import { Box, xcss } from '@atlaskit/primitives';
import { ProductSearchInputSkeletonInteractive } from '@atlassian/product-search-dialog/skeleton';
import { QuickFindSkeleton } from '@atlassian/search-page/quick-find-skeleton';
import { fg } from '@confluence/feature-gating';
import { CONTEXT_PATH } from '@confluence/named-routes/src';
import type { RoutesContextType } from '@confluence/route-manager';
import { RoutesContext } from '@confluence/route-manager';

import { i18n } from './messages';
import { token } from '@atlaskit/tokens';

interface InteractiveSearchInputSkeletonProps {
	theme?: SearchCSS;
	placeholder?: string;
	shouldFillContainer?: boolean;
}

export const generateInteractiveSkeletonOnEnter = (push: RoutesContextType['push']) => {
	return (e: React.KeyboardEvent) => {
		const input = e.target as HTMLInputElement;
		input.blur();
		push(`${CONTEXT_PATH}/search/?text=${input.value}`);
	};
};

export const InteractiveSearchInputSkeleton: FC<InteractiveSearchInputSkeletonProps> = ({
	theme,
	shouldFillContainer,
}) => {
	const intl = useIntl();
	const { push } = useContext(RoutesContext);

	const isQuickFindEnabled = fg('quick_find_refactor') && !fg('confluence_rovo_search_opt_out');
	// If the quick find refactor feature is enabled, we don't want to render the interactive skeleton
	if (isQuickFindEnabled) {
		return (
			<Box testId="quick-find-container" xcss={searchBarWrapper}>
				<Box xcss={searchBarContainer}>
					<QuickFindSkeleton onEnter={generateInteractiveSkeletonOnEnter(push)} />
				</Box>
			</Box>
		);
	}

	return (
		<ProductSearchInputSkeletonInteractive
			theme={theme}
			placeholder={intl.formatMessage(i18n.placeholder)}
			onEnter={generateInteractiveSkeletonOnEnter(push)}
			shouldFillContainer={shouldFillContainer}
			stickySearchEnabled
		/>
	);
};

const searchBarWrapper = xcss({
	position: 'relative',
	width: '200px',
	height: token('space.500'),
});

const searchBarContainer = xcss({
	position: 'absolute',
	top: 'space.0',
	right: 'space.0',
	bottom: 'space.0',
	display: 'flex',
	justifyContent: 'center',
	flexDirection: 'column',
	zIndex: 'dialog',
});
